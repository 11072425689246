import { Box, Flex, Radio, RadioGroup, Stack, VStack } from "@chakra-ui/react";
import * as React from "react";
import { useContext } from "react";
import {
  BiCalendar,
  BiCommentAdd,
  BiCreditCard,
  BiUser,
  BiHome,
  BiLogIn,
  BiLogOut,
} from "react-icons/bi";
import { AccountSwitcher } from "./AccountSwitcher";
import { NavGroup } from "./NavGroup";
import { NavItem } from "./NavItem";
import { useMobileMenuState } from "src/hooks/useMobileMenuState";

import { UserContext } from "src/components/context/UserContext";
import { useRouter } from "next/router";
import LawyerList from "../admin/LawyerList";
import SearchInput from "../input/SearchInput";
import { MobileMenuButton } from "./MobileMenuButton";
import FilterContext from "../context/FilterContext";
import auth from "../../api/authentication";
import Image from "next/image";
import Footer from "./Footer";
import Link from "next/link";

export default function SideBarNav({ children, noPadding = false }) {
  const { user } = useContext(UserContext);

  const { isOpen, toggle } = useMobileMenuState();

  const { filters, setFilters } = useContext(FilterContext);

  const router = useRouter();

  function filterChange(type, newVal) {
    setFilters((value) => ({ ...value, ...{ [type]: newVal } }));
  }
  return (
    <Flex id="app-container" h={"full"}>
      <Box
        minW={{ base: isOpen ? 64 : 0, md: 64 }}
        fontSize="sm"
        position={"relative"}
        zIndex={"99"}
        overflow={{ base: "hidden", md: "auto" }}
        transition={"all 0.2s"}
      >
        <Link href={"/"}>
          <a>
            <Flex
              h={30}
              my={5}
              mx={5}
              overflow={"hidden"}
              display={{ base: "none", md: "flex" }}
              justifyContent={"flex-start"}
              position={"relative"}
            >
              <Image
                src={"/images/logo-legalis-svart.png"}
                layout={"fill"}
                objectPosition={"left center"}
                objectFit={"contain"}
              />
            </Flex>
          </a>
        </Link>
        <Box marginTop={{ base: "50px", md: 0 }} minWidth={64}>
          {user?.role === "admin" && (
            <Flex h="full" direction="column" px="4" py="4">
              <AccountSwitcher />
              <Stack spacing="8" flex="1" overflow="auto" pt="8">
                <Stack spacing="1">
                  <NavItem
                    active={router.query.user === "all"}
                    icon={<BiHome />}
                    label="Alle saker"
                    onClick={() => router.push("/admin/saker?user=all")}
                  />
                  <NavItem
                    active={router.query.user === user.id}
                    icon={<BiCommentAdd />}
                    label="Mine saker"
                    onClick={() => router.push("/admin/saker?user=" + user.id)}
                  />
                  <NavItem
                    icon={<BiCommentAdd />}
                    active={router.query.user === "unassigned"}
                    label="Innboks"
                    onClick={() => router.push("/admin/saker?user=unassigned")}
                  />
                </Stack>
                {router.pathname === "/admin/saker" && (
                  <NavGroup label={"Filtere"}>
                    <Stack px={4}>
                      <RadioGroup
                        onChange={(val) => filterChange("status", val)}
                        value={filters.status}
                        background={"white"}
                        padding={2}
                        borderRadius={4}
                      >
                        <Stack direction="column">
                          <Radio value={"all"}>Alle</Radio>
                          <Radio value={"active"}>Aktive</Radio>
                          <Radio value={"done"}>Ferdige</Radio>
                        </Stack>{" "}
                      </RadioGroup>
                      <VStack pt={5}>
                        <LawyerList
                          onChange={(val) => filterChange("user", val)}
                          initialValue={undefined}
                        />
                        <SearchInput
                          placeholder="Søk etter tittel"
                          onChange={(e) =>
                            filterChange("search", e.target.value)
                          }
                          value={filters.search}
                        />
                        <SearchInput
                          placeholder="Søk etter klient navn/e-post"
                          onChange={(e) => filterChange("free", e.target.value)}
                          value={filters.free}
                        />
                      </VStack>
                    </Stack>
                  </NavGroup>
                )}
                <NavGroup label="Annet">
                  {user.name === "Administrator" && (
                    <NavItem
                      icon={<BiCreditCard />}
                      label="Innstillinger"
                      active={router.pathname === "/admin/administrasjon"}
                      onClick={() => router.push("/admin/administrasjon")}
                    />
                  )}
                  <NavItem
                    icon={<BiUser />}
                    label="Min side"
                    active={router.pathname === "/admin/minside"}
                    onClick={() => router.push("/admin/minside")}
                  />
                  <NavItem
                    icon={<BiLogOut />}
                    label="Logg ut"
                    onClick={() => auth.logout()}
                  />
                </NavGroup>
              </Stack>
            </Flex>
          )}
          {user?.role === "user" && (
            <Flex h="full" direction="column" px="4" py="4">
              <Stack spacing="1">
                <NavItem
                  active={router.pathname === "/mine-bookinger"}
                  icon={<BiHome />}
                  label="Mine saker"
                  onClick={() => router.push("/mine-bookinger")}
                />
              </Stack>
              <Stack spacing="8" flex="1" overflow="auto" pt="8">
                <Stack spacing="1">
                  <NavGroup label="Annet">
                    <NavItem
                      icon={<BiLogOut />}
                      label="Logg ut"
                      onClick={() => auth.logout()}
                    />
                  </NavGroup>
                </Stack>
              </Stack>
            </Flex>
          )}
          {!user && (
            <Flex h="full" direction="column" px="4" py="4">
              <Stack spacing="8" flex={1}>
                <NavGroup label="Meny">
                  <NavItem
                    icon={<BiHome />}
                    label="Hjem"
                    active={router.pathname === "/"}
                    onClick={() => router.push("/")}
                  />
                  <NavItem
                    icon={<BiCalendar />}
                    label="Bestill møte"
                    active={router.pathname === "/timebestilling"}
                    onClick={() => router.push("/timebestilling")}
                  />
                  <NavItem
                    icon={<BiLogIn />}
                    label="Logg inn"
                    onClick={() => auth.login()}
                  />
                </NavGroup>
              </Stack>
            </Flex>
          )}
        </Box>
      </Box>
      <Flex flexDir={"column"}>
        <Flex
          px={10}
          alignItems={"center"}
          py={2}
          bg={"#e2e8f0"}
          zIndex={99}
          top={0}
          w={"100%"}
          display={{ base: "flex", md: "none" }}
        >
          <MobileMenuButton onClick={toggle} isOpen={isOpen} />
          <Link href={"/"}>
            <a>
              <Flex
                position={"relative"}
                h={10}
                ml={2}
                minW={100}
                justifyContent={"flex-start"}
                display={{ base: "flex", md: "none" }}
              >
                <Image
                  src={"/images/logo-legalis-svart.png"}
                  layout={"fill"}
                  objectPosition={"left center"}
                  objectFit={"contain"}
                />
              </Flex>
            </a>
          </Link>
        </Flex>
        <Box
          w={{ base: "100vw", md: "calc(100vw - 264px)" }}
          maxHeight={{
            base: isOpen ? "calc(100vh - 145px)" : "10000000px",
            md: "100vh",
          }}
          minHeight={{
            base: "calc(100vh - 65px)",
            md: "100vh",
          }}
          padding={{ base: noPadding ? 0 : 2 }}
          overflow={{ base: isOpen ? "hidden" : "visible", md: "auto" }}
        >
          {children}
          {(router.pathname === "/" ||
            router.pathname === "/advokater" ||
            router.pathname === "/personvern" ||
            router.pathname === "/betingelser") && <Footer />}
        </Box>
      </Flex>
    </Flex>
  );
}
