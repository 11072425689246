import { Box, SimpleGrid, Stack } from "@chakra-ui/react";
import Link from "next/link";
import * as React from "react";
import { FooterHeading } from "./FooterHeading";

export const LinkGrid = (props) => (
  <SimpleGrid columns={1} {...props}>
    <Box minW="130px">
      <Stack>
        <Link href="/personvern">Personvern</Link>
        <Link href="/betingelser">Vilkår</Link>
        <a href={`${process.env.NEXT_PUBLIC_MAIN_URL}/kontakt`}>Kontakt oss</a>
      </Stack>
    </Box>
  </SimpleGrid>
);
