import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { AccountSwitcherButton } from "./AccountSwitcherButton";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";

export const AccountSwitcher = () => {
  return (
    <Menu>
      <AccountSwitcherButton />
    </Menu>
  );
};
