import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Img,
  useMenuButton,
} from "@chakra-ui/react";
import * as React from "react";
import { HiSelector } from "react-icons/hi";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { getLawyer } from "src/api/sanity";

export const AccountSwitcherButton = (props: FlexProps) => {
  const buttonProps = useMenuButton(props);
  const { user } = useContext(UserContext);
  const [url, setUrl] = React.useState(null);

  React.useEffect(() => {
    if (user && user.urlSlug) {
      getLawyer(user.urlSlug).then((data: any) => {
        setUrl(data.url);
      });
    }
  }, [user]);

  return (
    <Flex
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      outline="0"
      transition="all 0.2s"
      _focus={{ shadow: "outline" }}
    >
      <HStack flex="1" spacing="3">
        {user.urlSlug && (
          <Img
            w="8"
            h="8"
            rounded="md"
            objectFit="cover"
            src={url ? url : null}
            alt="Chakra UI"
          />
        )}

        <Box textAlign="center">
          <Box isTruncated fontWeight="semibold">
            {user.name}
          </Box>
        </Box>
      </HStack>
    </Flex>
  );
};
