import { ButtonGroup, IconButton } from "@chakra-ui/react";
import * as React from "react";
import { FaFacebook, FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";

export const SocialMediaLinks = (props) => {
  if (!process.env.NEXT_PUBLIC_FB_URL) return null;

  return (
    <ButtonGroup variant="ghost" color="gray.600" {...props}>
      <IconButton
        as="a"
        href={process.env.NEXT_PUBLIC_FB_URL}
        aria-label={`${process.env.NEXT_PUBLIC_COMPANY_NAME} på Facebook`}
        icon={<FaFacebook fontSize="20px" />}
      />
    </ButtonGroup>
  );
};
