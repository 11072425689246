import { Box, Flex } from "@chakra-ui/react";
import * as React from "react";
import { HiMenu, HiX } from "react-icons/hi";

interface MobileMenuButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

export const MobileMenuButton = (props: MobileMenuButtonProps) => {
  const { onClick, isOpen } = props;
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      display={{ base: "block", md: "none" }}
      ml="-8"
      mr="2"
      as="button"
      type="button"
      rounded="md"
      bg={"white"}
      p="0"
      minW={"50px"}
      minH={"50px"}
      fontSize="xl"
      color="black"
      onClick={onClick}
    >
      <Box srOnly>{isOpen ? "Lukk meny" : "Åpne meny"}</Box>
      <Box>
        {isOpen ? (
          <HiX style={{ margin: "0 auto", fontSize: 30 }} />
        ) : (
          <HiMenu style={{ margin: "0 auto", fontSize: 30 }} />
        )}
      </Box>
    </Flex>
  );
};
