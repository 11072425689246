import { Input } from "@chakra-ui/input";
import React from "react";

export default function SearchInput({ value, onChange, placeholder }) {
  return (
    <Input
      w="100%"
      flexGrow="1"
      size={"sm"}
      type="text"
      color={"grey.700"}
      background={"grey.700"}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  );
}
