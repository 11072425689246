import { chakra, useColorModeValue, useToken } from '@chakra-ui/react'
import Image from 'next/image'
import * as React from 'react'

export const Logo = (props) => {
  const [white, black] = useToken('colors', ['white', 'gray.800'])
  return (
    <Image src="/images/logo-legalis-svart.png" width="100" height="30" alt="Logo" />
  )
}
