import { Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";

const sanityClient = require("@sanity/client");
const BlockContent = require("@sanity/block-content-to-react");
import imageUrlBuilder from "@sanity/image-url";
const client = sanityClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_CODE,
  dataset: "production",
  useCdn: true,
});

export const builder = imageUrlBuilder(client);

export const BlockRenderer = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, node }: { children: any; node: any } = props;
  const { style = "normal" } = node;
  if (style === "blockquote") {
    return <blockquote>{children}</blockquote>;
  }
  if (style === "normal") {
    return <Text my={2}>{children}</Text>;
  }
  if (style === "h2") {
    return (
      <Heading size="lg" mt={5}>
        {children}
      </Heading>
    );
  }
  if (style === "h3") {
    return (
      <Heading size="md" mt={5}>
        {children}
      </Heading>
    );
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

export const ListRenderer = (props) => (
  // Fall back to default handling
  <UnorderedList>{props.children}</UnorderedList>
);
export const ListItemRenderer = (props) => (
  // Fall back to default handling
  <ListItem>{props.children}</ListItem>
);

export async function getLawyer(slug) {
  return new Promise(async (resolve, reject) => {
    try {
      const query =
        '*[_type == "author" && slug.current == $slug]{... , categories[]->, image{asset->{path,url}}, education[], experience[], "articles": *[ _type == "post" && author._ref == ^._id ]{categories->, slug, _createdAt, title, author{..., image{asset->{path,url}}}}}';

      const employees = await client.fetch(query, { slug: slug });
      if (employees.length > 0) {
        resolve({
          url: builder.image(employees[0].image).saturation(-100).url(),
          data: employees[0],
        });
      }
    } catch (err) {
      return reject(null);
    }
  });
}

export default client;
