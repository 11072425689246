import { Box, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { Configuration, LawyerServiceApi } from "../../api/gen";
import config from "../../config/api-config";

export default function LawyerList({ initialValue, onChange }) {
  const [selected, setSelected] = useState(null);

  const { data: lawyers } = useSWR("/lawyers/all/admin", () =>
    new LawyerServiceApi(new Configuration(config)).getAllAdmin()
  );

  const options = lawyers?.docs
    ? lawyers.docs.map((lawyer) => ({
        value: lawyer.id,
        label: lawyer.name,
      }))
    : [];

  useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (initialValue && options) {
      if (options.find((elem) => elem.value === initialValue)) {
        setSelected(options.find((elem) => elem.value === initialValue));
      }
    }
  }, [initialValue, options]);

  return (
    <Box w={"100%"}>
      <Select
        placeholder="Velg en advokat"
        background={"white"}
        onChange={(e) => {
          if (e.target.value === "") {
            setSelected("all");
          } else {
            setSelected(e.target.value);
          }
        }}
        size={"sm"}
      >
        {options.map((elem, idx) => (
          <option style={{ color: "black" }} value={elem.value} key={idx}>
            {elem.label}
          </option>
        ))}
      </Select>
    </Box>
  );
}
